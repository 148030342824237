<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            //'id',
            helpers.monthName,
            { ...helpers.validIcons, key: 'generate' },
            { ...helpers.validIcons, key: 'is_daily' },
            { ...helpers.validIcons, key: 'is_superfinanciera' },
            { ...helpers.validIcons, key: 'automatic_reverse' },
            helpers.rounding,
            helpers.decimalValue,
            helpers.createdAt
          ]"
          :config="{
            url: 'admin/property_interests',
            route: '/admin/parameters/property_interests',
            name: 'property_interest',
            params: { _lists: 'months,roundings' },
            duplicate: true,
            latest: 'month_id'
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'PropertyInterests',
  mixins: [crudMixin]
}
</script>
